import { createSlice } from '@reduxjs/toolkit';
import type { TOURIST, GENDER } from '@lt/api/dist/types/tourists';

import type { DocType } from 'src/components/Tourists/types';
import { name } from '.';

export type TouristsState = {
  gender: GENDER;
  name?: TOURIST['name'];
  surname?: TOURIST['surname'];
  patronymic?: TOURIST['patronymic'];
  docType: DocType;
  passport_number?: TOURIST['passport_number'];
  passport_expires_on?: TOURIST['passport_expires_on'];
  birthday?: TOURIST['birthday'];
  nationality_id: TOURIST['nationality_id'];
  age?: number | null;
  hideDocInputs: boolean;
  hidePatronymic?: boolean;
};

export const initialState: TouristsState[] = [];

const touristsValidation = createSlice({
  name,
  initialState,
  reducers: {
    changeTouristData: (state, action) => {
      const { index, data } = action.payload;

      state[index] = data;

      if (data.hideDocInputs) {
        state[index].passport_number = undefined;
        state[index].passport_expires_on = undefined;
      }

      if (data.hidePatronymic) {
        state[index].patronymic = undefined;
      }
    },
    clearTouristData: () => initialState,
  },
});

export const { changeTouristData, clearTouristData } =
  touristsValidation.actions;
export default touristsValidation.reducer;
