import type {
  PostPackagesActualizeAlternativesResponse,
  TourInfo,
} from '@lt/api/dist/methods/packages/types';
import type { PayloadAction, Draft } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';

import type { OrderPriceInfo } from 'src/routes/Checkout/store/order/types';
import type {
  ActualizeAlternativesSlice,
  SelectWithAlternativesPayload,
} from './types';

export const name = 'actualizeAlternatives';

// Соседние рейсы на чекауте

export const initialState: ActualizeAlternativesSlice = {
  status_information: {
    status: 'pending',
    tours_total_count: 0,
    tours_processed_count: 0,
    tours_alternatives_count: 0,
  },
  statistic: {
    flights_count: 0,
    cheapest_option: null,
  },
  alternative_tours: [],
  alternatives_actualization_session_id: '',
  isAltActualizationDone: false,
  isChangingPackage: false,
  selectedFlightPairId: '',
  upgradedServices: [],
  failed_tours: [],
  filtered_tours: [],
  initial_tours: [],
  previous_package_id: '',
  previous_operator_id: undefined,
};

const actualizeAlternatives = createSlice({
  name,
  initialState,
  reducers: {
    setActualizeAlternativesInfo(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<PostPackagesActualizeAlternativesResponse>,
    ) {
      const { payload } = action;
      state = { ...state, ...payload };
      return state;
    },
    setPreviousCheapestPackagePrice(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<number>,
    ) {
      const { payload } = action;
      state.previousCheapestPackagePrice = payload;
      return state;
    },
    setAltTour(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<{ tourInfo: TourInfo; tourId: string }>,
    ) {
      const { tourInfo, tourId } = action.payload;
      const altTourIndex = state.alternative_tours.findIndex(
        ({ tour_id }) => tour_id === tourId,
      );
      if (altTourIndex !== -1) {
        state.alternative_tours[altTourIndex] = tourInfo;
      }
      return state;
    },
    setIsAltActualizationDone(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<boolean>,
    ) {
      state.isAltActualizationDone = action.payload;
    },
    setUpgradedServices(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<OrderPriceInfo[]>,
    ) {
      state.upgradedServices = action.payload;
    },
    setIsChangingPackage(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<boolean>,
    ) {
      state.isChangingPackage = action.payload;
    },
    selectFlightWithAlternatives(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<SelectWithAlternativesPayload>,
    ) {
      state.selectedFlightPairId = action.payload.flight_pair_id;
    },
    setPreviousPackageId(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<string>,
    ) {
      state.previous_package_id = action.payload;
    },
    setPreviousOperatorId(
      state: Draft<ActualizeAlternativesSlice>,
      action: PayloadAction<number>,
    ) {
      state.previous_operator_id = action.payload;
    },
  },
});

export const {
  setActualizeAlternativesInfo,
  setPreviousCheapestPackagePrice,
  setAltTour,
  setIsAltActualizationDone,
  setUpgradedServices,
  setIsChangingPackage,
  selectFlightWithAlternatives,
  setPreviousPackageId,
  setPreviousOperatorId,
} = actualizeAlternatives.actions;

export const fetchAlternatives = createAction(`${name}/fetchAlternatives`);
export const changePackage = createAction<unknown>(`${name}/changePackage`);
export const changePackageIdSuccess = createAction(
  `${name}/changePackageIdSuccess`,
);
export const sendInitAltActualizationAnalytics = createAction<{
  initTours: Pick<ActualizeAlternativesSlice, 'initial_tours'>;
}>(`${name}/sendInitAltActualizationAnalytics`);
export const sendAltActualizationAnalytics = createAction<{
  prevToursState: Pick<
    ActualizeAlternativesSlice,
    'alternative_tours' | 'failed_tours' | 'filtered_tours'
  >;
  newToursState: Pick<
    ActualizeAlternativesSlice,
    'alternative_tours' | 'failed_tours' | 'filtered_tours'
  >;
}>(`${name}/sendAltActualizationAnalytics`);

export default actualizeAlternatives.reducer;
