import URIjs from 'urijs';
import { FLIGHTS_COMPONENT_ID } from 'src/routes/Checkout/CheckoutRoutes/PackageCheckout/store/flights/constants';

import { scrollToElement } from '../../../utils/domUtils';

/**
 * Возвращает packageId из адресной строки
 * @returns {string}
 */
export const getUrlPackageId = (): string => {
  const arr = URIjs().path().split('/packages/');
  const [packageId] = arr[1]?.split('/') ?? '';

  return packageId ?? '';
};

/**
 * Возвращает полный путь к роуту с packageId
 * @param {string} path роут
 * @returns {string}
 */
export const getFullPath = (path: string): string =>
  `/${getUrlPackageId()}${path}`;

const PACKAGE_PATH_PREFIX_REGEX = new RegExp('/\\d+');
export const getStepPath = (path: string) =>
  path && path.replace(PACKAGE_PATH_PREFIX_REGEX, '');

/**
 * Скролл к компоненту перелётов
 */
export const scrollToFlights = () => {
  const flightsElement = document.getElementById(FLIGHTS_COMPONENT_ID);
  scrollToElement(flightsElement);
};

/**
 * Снимает фокус с активного элемента
 */
export const blurActiveElement = () => {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
};
