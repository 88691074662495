import { i18n } from '@lt/localization';

import x30x30 from 'src/images/header_banner/headerBanner.png';
import webp_x30x30 from 'src/images/header_banner/headerBanner.webp';

export const defaultHeaderBanner = {
  desktopText: `${i18n.t('headerBanner.title')} ${i18n.t(
    'headerBanner.description',
  )}`,
  redirectUrl: undefined,
  imageSrc: {
    x30x30,
    webp_x30x30,
  },
};
